@import '~react-big-calendar/lib/sass/styles';

.rbc-btn-group {
  @apply text-center md:text-left;
  flex: 1;
}

.rbc-toolbar button {
  @apply text-white hover:bg-mainDark hover:text-white border-gray-500 hover:border-gray-500;
}

.rbc-header {
  @apply font-medium;
}

.rbc-today {
  @apply bg-mainTheme bg-opacity-30;
}

.rbc-off-range-bg {
  @apply bg-gray-700 bg-opacity-75;
}

.rbc-overlay {
  @apply bg-dark1 border-gray-600;
  z-index: 100;
}

.rbc-show-more {
  @apply py-1 bg-gray-400 bg-opacity-30 text-center no-underline font-bold;
}

.rbc-overlay-header {
  @apply border-gray-600;
}

.rbc-header,
.rbc-header + .rbc-header,
.rbc-month-row + .rbc-month-row,
.rbc-day-bg + .rbc-day-bg,
.rbc-month-view {
  @apply border-gray-500;
}
