html a {
  color: $link-color;
  text-decoration: $link-decoration;
  background-color: transparent; // Remove the gray background on active links in IE 10.

  @include hover() {
    color: $link-hover-color !important;
    text-decoration: $link-hover-decoration;
  }
}

p,
ul {
  margin-bottom: 0;
}

body {
  background-color: #16142e;
}

.fa-spin {
  animation: spin infinite 4s linear;
}

a.no-hover {
  @include hover() {
    color: inherit !important;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav-link:hover {
  color: white !important;
}

.pages-tabs {
  .nav-link {
    @apply py-4 outline-none text-gray-400 mr-3;
    margin-bottom: -1px;
  }

  .nav-link:hover,
  .nav-link:focus {
    color: $body-color;
  }

  .nav-link.active {
    @apply border-pink;
    border-bottom-width: 3px;
    color: $body-color;
  }
}

.progress-bar-striped {
  @include gradient-striped(darken(#44f1a6, 10%));
  background-size: $progress-height $progress-height;
}

.btn-main {
  @apply py-3 border-2;
  @include button-variant(#6825e7, $mainLighter);
  background: linear-gradient(to right, #6825e7, #aa58b8);
  box-shadow: 0 0 24px 0 rgba(133, 60, 210, 0.5);
  outline: none;
  color: white;
  text-decoration: none;

  &:hover, &:active, &:focus {
    color: inherit !important;
    box-shadow: 0 0 24px 0 rgba(133, 60, 210, 0.5);
  }
}

// Gleam whitelist
.e-widget-wrapper {
  max-width: 700px !important;
}

iframe.e-embed-frame {
  max-width: 700px !important;

  html.enter {
    max-width: 700px !important;
  }
}
